<template>
  <div class="app-container merch-card">
    <!-- 搜索列 -->
    <el-form
      ref="queryParams"
      :model="queryParams"
      :inline="true"
      @keyup.enter.native="handleCurrentChange"
    >
      <el-form-item class="minItem" label="账单创建时间：">
        <el-date-picker
          v-model="dateTime"
          type="daterange"
          range-separator="至"
          start-placeholder="账单开始日期"
          end-placeholder="账单结束日期"
          unlink-panels
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item label="供应商：">
        <el-input
          v-model="queryParams.supplier"
          placeholder="请输入供应商名称"
          clearable
        />
      </el-form-item>
      <el-form-item label="账单状态：">
        <el-select
          clearable
          v-model="queryParams.billStatus"
          placeholder="请选择账单状态"
        >
          <el-option label="未结清" value="0"></el-option>
          <el-option label="已结清" value="1"></el-option>
          <el-option label="已作废" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="handleCurrentChange"
          style="width: 80px"
          >查 询</el-button
        >
        <el-button @click="handleReset" style="width: 80px" plain
          >重 置</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 列表 -->
    <el-table
      :data="searchList"
      v-loading="loading"
      border
      style="width: 100%"
      max-height="600px"
      :header-cell-style="{ background: '#F2F3F5' }"
    >
      <el-table-column prop="billNumber" label="账单单号" align="center" />
      <el-table-column prop="supplier" label="供应商" align="center" />
      <el-table-column prop="billPayment" label="账单金额（元）" align="center">
        <template slot-scope="{ row }">
          <div v-if="row.billPayment">￥{{ row.billPayment }}</div>
          <div v-else>—</div>
        </template>
      </el-table-column>
      <el-table-column prop="totalAmount" label="实收金额（元）" align="center">
        <template slot-scope="{ row }">
          <div v-if="row.totalAmount">￥{{ row.totalAmount }}</div>
          <div v-else>—</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="remainingBillAmount"
        label="剩余账单金额"
        align="center"
      >
        <template slot-scope="{ row }">
          <div v-if="row.remainingBillAmount">
            ￥{{ row.remainingBillAmount }}
          </div>
          <div v-else>—</div>
        </template>
      </el-table-column>
      <el-table-column prop="createDate" label="账单创建时间" align="center" />
      <el-table-column prop="billStatus" label="账单状态" align="center">
        <template slot-scope="{ row }">
          <div>
            {{
              row.billStatus == 1
                ? "已结清"
                : row.billStatus == 0
                ? "未结清"
                : "已作废"
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="{ row }">
          <div>
            <span
              class="btsed"
              v-if="row.billStatus == 0"
              @click="addpaytin(row)"
              >添加付款</span
            >
            <span class="btsed" @click="viewDetails(row)">查看明细</span>
            <span
              class="btsed"
              v-if="row.billStatus === 0"
              @click="withdrawBilds(row)"
              >作废账单</span
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="footer">
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.currPage"
        :limit.sync="queryParams.pageSize"
        @pagination="getSearchList"
      />
    </div>
    <!-- 添加收款信息 -->
    <el-dialog
      title="添加收款信息"
      :visible.sync="dialogpaytinfor"
      :before-close="dialogpayout"
      :close-on-click-modal="false"
      width="550px"
      style="margin-top: -5vh"
    >
      <el-form
        style="margin: -30px 0px"
        ref="payform"
        :model="paytinfordata"
        label-width="130px"
        :rules="payrules"
      >
        <el-form-item label="账单单号：" prop="billNumber">
          <div>{{ paytinfordata.billNumber }}</div>
        </el-form-item>
        <el-form-item label="剩余账单金额：" prop="remainingBillAmount">
          <div>{{ paytinfordata.remainingBillAmount }}元</div>
        </el-form-item>
        <!-- <el-form-item label="收款账号：" prop="accountId" :required="true">
          <el-select
            style="width: 350px"
            clearable
            v-model="paytinfordata.accountId"
            filterable
            placeholder="请选择收款账号"
            @change="changebank"
          >
            <el-option
              v-for="(item, index) in receivaccount"
              :key="index"
              :label="item.accountName"
              :value="item.id"
            />
          </el-select>
        </el-form-item> -->
        <el-form-item label="收款账号：" prop="accountNumber">
          <el-input
            style="width: 350px"
            v-model="paytinfordata.accountNumber"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="收款金额：" prop="amount" :required="true">
          <el-input
            style="width: 350px"
            v-model="paytinfordata.amount"
            placeholder="请输入"
            @input="inputed(paytinfordata.amount)"
            maxlength="12"
            clearable
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="收款流水号：" prop="collectionSerialNumber">
          <el-input
            style="width: 350px"
            v-model="paytinfordata.collectionSerialNumber"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input
            style="width: 350px"
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
            maxlength="200"
            v-model="paytinfordata.remark"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="转账凭证：" prop="transferVoucher">
          <div>
            <div class="fx" @click="stateder = false">
              <UpimgList
                :imgnumber="3"
                :disabled="false"
                :imglist="paytinfordata.transferVoucher"
                @Upimgsun="Upimgsun"
                :imageSize="3"
                :nourl="true"
              />
            </div>
            <div class="ontext fx mar10" style="line-height: 20px">
              <div>温馨提示：</div>
              <div>
                <div>1、最多支持上传三张转账凭证；</div>
                <div>2、转账凭证支持3M以内的.png和.jpg格式文件。</div>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="text-right">
        <el-button type="primary" @click="addpaytined" :loading="loading"
          >添 加</el-button
        >
        <el-button @click="dialogpayout">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UpimgList from "@/components/Updata/UpimgList.vue";
import { createNamespacedHelpers } from "vuex";
const billList = createNamespacedHelpers("billList");
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
export default {
  name: "",
  components: {
    UpimgList,
  },
  data() {
    return {
      loading: false,
      total: 0,
      //列表搜索
      queryParams: {
        currPage: 1,
        pageSize: 10,
        startTime: "",
        endTime: "",
        supplier: "", //供应商名字
        billStatus: "", //账单状态(0:未结清, 1:已结清）
      },
      dateTime: [],
      searchList: [], //账单列表

      dialogpaytinfor: false, // 添加付款信息弹窗
      //收款信息
      paytinfordata: {
        supplierId: "", //供应商id
        creatorId: "", //创建人
        accountNumber: "", //收款账号

        billNumber: "", //账单单号
        remainingBillAmount: "", //剩余账单金额
        // accountId: "", //收款账号(id)
        accountNumber: "", //收款账号
        amount: "", //收款金额
        collectionSerialNumber: "", //收款流水号
        remark: "", //备注信息
        transferVoucher: [], //凭证
      },
      receivaccount: [], //收款账号
      payrules: {
        accountNumber: [
          { required: true, message: "请输入收款账号", trigger: "blur" },
        ],
        amount: [
          {
            required: true,
            message: "请输入收款金额",
            trigger: ["blur", "change"],
          },
        ],
        collectionSerialNumber: [
          { required: true, message: "请输入收款流水号", trigger: "blur" },
        ],
        transferVoucher: [
          { required: true, message: "请选择转账凭证", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    ...commonIndex.mapState([
      "detailsSeach",
    ]),
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.queryParams = this.detailsSeach[router];
      this.dateTime = this.detailsSeach[router].dateTime;
    }
    this.getSearchList();

  },
  methods: {
    ...billList.mapActions([
      "billList", //账单列表
      "addBillInfo", //添加付款
      "configet", //获取供应商对公转账设置
      "withdrawBillcancel", //作废账单
    ]),
    ...commonIndex.mapMutations(["changeSeach"]),
    inputed(value) {
      if (value == "") return;
      value = value.replace(/[^\d.]/g, ""); //清除'数字'和'.'以外的字符
      value = value.replace(/^\./g, ""); //验证第一个字符是数字而不是字符
      value = value.replace(/\.{2,}/g, "."); //只保留第一个.清除多余的
      value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      this.paytinfordata.amount = value;
    },
    async getSearchList() {
      this.loading = true;
      if (this.dateTime && this.dateTime.length > 0) {
        this.queryParams.startTime = this.dateTime[0];
        this.queryParams.endTime = this.dateTime[1];
      } else {
        this.queryParams.startTime = "";
        this.queryParams.endTime = "";
      }
      this.billList(this.queryParams).then((res) => {
        let router = this.$router.history.current.path.substr(1);
        let save = { ...this.queryParams };
        save.dateTime = this.dateTime;
        save.router = router;
        this.changeSeach(save);
        this.loading = false;
        this.searchList = res.content.list;
        this.total = res.content.totalRecords;
      });
    },
    //搜索
    handleCurrentChange() {
      this.queryParams.currPage = 1;
      this.getSearchList();
    },
    //重置
    handleReset() {
      this.dateTime = [];
      Object.assign(this.queryParams, this.$options.data().queryParams);
      this.getSearchList();
    },
    /** 查看明细 */
    viewDetails(row) {
      this.$router.push({
        path: "/BillDetails",
        query: {
          billNumber: row.billNumber,
        },
      });
    },
    /** 作废账单 */
    withdrawBilds(row) {
      this.$confirm(
        `<div>确认作废账单？</div><div>作废后需要重新申请提现</div>`,
        `删除${row.billNumber}`,
        {
          closeOnClickModal: false,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          showClose: false,
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          this.withdrawBillcancel({ billNumber: row.billNumber }).then(
            (res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "作废成功!",
                });
                if (
                  this.queryParams.currPage > 1 &&
                  this.searchList.length == 1
                ) {
                  this.queryParams.currPage--;
                }
                this.getSearchList();
              }
            }
          );
        })
        .catch(function () {});
    },
    /** 添加收款信息弹窗 */
    async addpaytin(row) {
      await Object.assign(
        this.paytinfordata,
        this.$options.data().paytinfordata
      );
      // await this.getreceiva(row);
      await this.getaddpay(row);
      this.dialogpaytinfor = true;
    },
    async dialogpayout() {
      await this.$refs.payform.resetFields();
      this.dialogpaytinfor = false;
    },
    /** 获取收款账号 */
    getreceiva(row) {
      this.configet({ businessId: row.supplierId }).then((res) => {
        if (res.code == "000000") {
          this.receivaccount = res.content;
        }
      });
    },
    /** 获取账单收款信息 */
    async getaddpay(row) {
      await Object.assign(this.paytinfordata, row);
      this.paytinfordata.creatorId = window.sessionStorage.getItem("admin_id");
      this.paytinfordata.billNumber = row.billNumber;
    },
    //切换收款账号
    changebank(val) {
      this.receivaccount.forEach((v) => {
        if (v.id == val) {
          // Object.assign(this.paytinfordata, v);
          this.paytinfordata.accountId = v.id;
          this.paytinfordata.accountNumber = v.accountName;
        }
      });
    },
    //拖拽图片
    Upimgsun(imglist) {
      this.paytinfordata.transferVoucher = [...imglist];
    },
    /** 添加收款信息 */
    addpaytined() {
      this.$refs.payform.validate((valid) => {
        if (valid) {
          var data = { ...this.paytinfordata };
          var imgtxt = "";
          data.transferVoucher.forEach((v, i) => {
            imgtxt = imgtxt + v;
            if (data.transferVoucher.length > i + 1) {
              imgtxt = imgtxt + ";";
            }
          });
          data.transferVoucher = imgtxt;
          if (
            Number(this.paytinfordata.remainingBillAmount) ==
            Number(this.paytinfordata.amount)
          ) {
            this.$confirm(
              `确认已收款完成？
              确认后将同意供应商对关联订单提现`,
              "提示",
              {
                closeOnClickModal: false,
                cancelButtonText: "取消",
                confirmButtonText: "确认",
              }
            )
              .then(() => {
                this.loading = true;
                this.addBillInfo(data).then((res) => {
                  this.loading = false;
                  if (res.code == "000000") {
                    this.$message.success("添加收款成功");
                    this.dialogpayout();
                    this.getSearchList();
                  }
                });
              })
              .catch(() => {});
          } else {
            this.loading = true;
            this.addBillInfo(data).then((res) => {
              this.loading = false;
              if (res.code == "000000") {
                this.$message.success("添加收款成功");
                this.dialogpayout();
                this.getSearchList();
              } else {
                this.$message.error(res.message);
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  .merch-table {
    margin-top: 1%;
    width: 100%;
    height: 77%;
  }
  .userBtn {
    display: flex;
    justify-content: center;
    div {
      margin-left: 5px;
      color: #1abca9;
      cursor: pointer;
    }
  }
}
::v-deep .el-range-separator {
  width: 10%;
}
</style>
